<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报名信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span><span style="color: #4096ff;">{{ name }}</span>信息筛选</span>
        </el-col>
      </el-row>
      <el-form :model="queryForm" class="filter_Form" label-width="80px" ref="queryFormRef">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="姓名" prop="userName">
              <el-input v-model="queryForm.userName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="queryForm.phone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="right">
            <el-button type="primary" icon="el-icon-search"
              @click="search">查询</el-button>
            <el-button type="warning" icon="el-icon-refresh-right"
              @click="queryReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <span>选手信息列表（共<span style="color: #4096ff;">{{ userList.length }}</span></span>人）
        </el-col>
        <el-col :span="12">
          <div style="text-align: right">
            <el-button type="primary" size="small" plain @click="exportStu">导出数据</el-button>
            <el-button type="primary" size="small" plain @click="downloadFn">下载裁判席用表</el-button>
            <el-button type="success" size="small" plain @click="addFn">添加选手</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="userList" header-cell-class-name="tableHeaderStyle" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="上场顺序/抽签" prop="competitionNumber"></el-table-column>
        <el-table-column label="选手姓名" prop="userName"></el-table-column>
        <el-table-column label="性别" prop="sex" :formatter="sexFormat"></el-table-column>
        <el-table-column label="英文名" prop="enName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="学校名称" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="grade" :formatter="gradeFormatter"></el-table-column>
        <el-table-column label="签到状态" prop="signIn">
          <template slot-scope="scope">
            <el-tag :type="scope.row.signIn ? 'success' : 'danger'" size="mini">
              {{ scope.row.signIn ? '已' : '未' }}签到
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="晋级状态" prop="promotion">
          <template slot-scope="scope">
            <el-tag :type="scope.row.promotion === 1 ? 'success' :scope.row.promotion === 2 ? 'danger' : 'info'" size="mini">
              {{ scope.row.promotion === 2 ? '未晋级' : scope.row.promotion === 1 ? '晋级' : '未设置' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="margin-right: 10px;"
              @click="moveFn(scope.row, true)">移动</el-button>
            <el-button type="text" size="small" style="margin-right: 10px;"
              @click="delFn(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="更换考场" :visible.sync="moveVisible" width="500px">
      {{title}}
      <el-select v-model="cityRoomId" placeholder="请选择考场">
        <el-option
          v-for="item in rooms"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          :disabled="item.id === queryForm.cityRoomId">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doMove">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加选手" :visible.sync="addVisible" width="1000px">
      <el-table :data="stuList" header-cell-class-name="tableHeaderStyle" border stripe @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="上场顺序/抽签" prop="competitionNumber"></el-table-column>
        <el-table-column label="选手姓名" prop="userName"></el-table-column>
        <el-table-column label="性别" prop="sex" :formatter="sexFormat"></el-table-column>
        <el-table-column label="英文名" prop="enName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="学校名称" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="grade" :formatter="gradeFormatter"></el-table-column>
        <el-table-column label="签到状态" prop="signIn">
          <template slot-scope="scope">
            <el-tag :type="scope.row.signIn ? 'success' : 'danger'" size="mini">
              {{ scope.row.signIn ? '已' : '未' }}签到
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { nmpGetUserByRoom, nmpDelStu, nmpMoveStu, nmpGetNoAllocationUsers, nmpSetUsertoRoom } from '@/http/api'

export default {
  data () {
    return {
      dict_grade: this.$userInfo.getGradeData(),
      dict_district: [],
      addInfoDialog: false,
      boolData: this.$userInfo.dict_boolData(),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      queryForm: {
        activityType: this.$chnEngStatusCode.activityType,
        cityRoomId: '',
        userName: '',
        phone: ''
      },
      name: '',
      level: '',
      userList: [],
      moveVisible: false,
      rooms: [],
      cityRoomId: '',
      title: '',
      stuList: [],
      addVisible: false,
      userIdList: []
    }
  },
  created () {
    this.name = this.$route.query.name
    this.level = this.$route.query.level
    this.queryForm.cityRoomId = this.$route.query.id
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    gradeFormatter (data) {
      return data.grade ? this.dict_grade.find(item => item.dictValue === Number(data.grade)).dictLabel : ''
    },
    sexFormat (data) {
      return String(data.sex) === '1' ? '男' : String(data.sex) === '0' ? '女' : ''
    },
    getList () {
      console.log('queryForm', this.queryForm)
      nmpGetUserByRoom(this.queryForm).then((res) => {
        this.userList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    search () {
      this.getList()
    },
    queryReset () {
      this.queryForm.userName = ''
      this.queryForm.phone = ''
      this.getList()
    },
    // 导出数据
    exportStu () {
      var downLoadUrl = this.$http.defaults.baseURL + 'cityMatch/half/exportUserByRoom'
      var formData = JSON.parse(JSON.stringify(this.queryForm))
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', formData)
    },
    downloadFn () {
      var downLoadUrl = this.$http.defaults.baseURL + 'cityMatch/half/downRefereeExcel'
      var formData = JSON.parse(JSON.stringify(this.queryForm))
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', formData)
    },
    addFn () {
      nmpGetNoAllocationUsers({
        activityType: this.queryForm.activityType,
        level: this.level,
        raceSchedule: 19,
        season: this.$chnEngStatusCode.defaultSeason,
        round: 1
      }).then(res => {
        if (res.code === 200) {
          this.addVisible = true
          this.stuList = res.data
        }
      })
    },
    handleSelectionChange (val) {
      this.userIdList = val.map(item => item.userId)
    },
    doAdd () {
      if (!this.userIdList.length) return this.$message.warning('请选择要添加的选手')
      nmpSetUsertoRoom({
        activityType: this.queryForm.activityType,
        cityRoomId: this.queryForm.cityRoomId,
        level: this.level,
        raceSchedule: 19,
        season: this.$chnEngStatusCode.defaultSeason,
        userIdList: this.userIdList
      }).then(res => {
        if (res.code === 200) {
          this.addVisible = false
          this.getList()
        }
      })
    },
    delFn (item) {
      this.$confirm(`确认将【${item.competitionNumber}号】选手【${item.userName}】移出${this.name}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认删除
        nmpDelStu({
          activityType: this.queryForm.activityType,
          id: item.id,
          level: this.level,
          raceSchedule: 19,
          season: this.$chnEngStatusCode.defaultSeason
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('已移出')
            this.getList()
          }
        })
      }).catch(() => {
      })
    },
    moveFn (item) {
      this.rooms = item.cityRooms
      this.moveVisible = true
      this.id = item.id
      this.title = `移动【${item.competitionNumber}号】选手【${item.userName}】至`
    },
    doMove () {
      nmpMoveStu({
        activityType: this.queryForm.activityType,
        id: this.id,
        level: this.level,
        raceSchedule: 19,
        season: this.$chnEngStatusCode.defaultSeason,
        cityRoomId: this.cityRoomId
      }).then(res => {
        this.moveVisible = false
        this.$message.success('已移动')
        this.getList()
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
